import React from 'react'

export default function DisplayTable(props) {
  return (
    <table className="min-w-full divide-y divide-gray-300 overflow-auto">
    <thead>
      <tr>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3">
          SAP System
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Mandt
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          ARE
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          ARE Name
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Company Code
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Depth Structure
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Vendor Name
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Vendor Number
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Trading Partner
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Vendor IFA 
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          PO Number
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Creation Date
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Currency
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          PO Amount
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          PO Creator
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          First Name
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Last Name
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Email
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          User - Ordinary Course of Business
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          User - Market Conditions
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          User Response Date
        </th>

        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Power User - Ordinary Course of Business
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Power User - Market Conditions
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Power User Response Date
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Power User
        </th>
        <th scope="col" className="px-12 py-3.5 text-center text-sm font-semibold text-gray-900">
          Note
        </th>
        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
          Modify Classification
        </th>
      </tr>
    </thead>
    <tbody className="bg-white">
      {props.data.map((i, index) => (
        <tr key={index} className="even:bg-gray-50">
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_SOURSYS}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_MANDT} </td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.T001_RCOMP}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.T001_BUTXT}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_BUKRS}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.DEPTH_STRUCTURE}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_NAME1}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_LIFNR}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_VBUND}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_CMD_RES_12}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_EBELN}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_ERDAT}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_WAERS}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKPO_NETWR}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_ERNAM}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.ADR6_GIVEN_NAME}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.ADR6_LAST_NAME}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.ADR6_EMAIL}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.USER_ORDINARY_COURSE}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.USER_MARKET_CONDITIONS}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.USER_RESPONSE_DATE}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.POWERUSER_ORDINARY_COURSE}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.POWERUSER_MARKET_CONDITIONS}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.POWERUSER_RESPONSE_DATE}</td>
            <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.POWERUSER_NAME}</td>
            <td className="whitespace-nowrap px-12 py-4 text-center truncate overflow-hidden text-sm text-gray-500" title={i.POWERUSER_NOTE}>{i.POWERUSER_NOTE}</td>
          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-md font-semibold sm:pr-3">
            {props.submittedEbeln.includes(i.EKKO_EBELN) ? <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">Submitted</td> :
              <a onClick={()=>props.handleClassify(i.EKKO_SOURSYS, i.EKKO_MANDT, i.EKKO_EBELN)} className="text-red-600 hover:text-red-900 p-2 rounded-md bg-red-200 cursor-pointer">
                Modify<span className="sr-only">, {}</span>
              </a>
            }
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  )
}

