import React, { useState, useEffect } from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ReportTable from 'components/reportTable/reportTable'
import InputField from 'pages/mdDashboard/pages/mdgEnd2End/components/inputField'
import GenericFooter from "components/navigation/footer/GenericFooter";
import SuccessAlertV2 from "components/alert/SuccessAlert";
import WarningAlertV2 from "components/alert/WarningAlertV2";
import { axios_cr_status_requests } from "./support_functions/axiosRequest";
import { exportToExcel } from "./support_functions/exportToExcel";
import SearchTypeDropdown from "./components/searchTypeDropdown";

export default function MdgEnd2End() {

    const [loading, setLoading] = useState(false)

    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [showDataTable1, setShowDataTable1] = useState(false)
    const [showDataTable2, setShowDataTable2] = useState(false)
    const [showDataTable3, setShowDataTable3] = useState(false)
    const [showDataTable4, setShowDataTable4] = useState(false)
    const [crStatusTableData, setCrStatusTableData] = useState([])
    const [replicationStatusTableData, setReplicationStatusTableData] = useState([])
    const [slpReplicationTable, setSlpReplicationTable] = useState([])
    const [eccBlockTable, setCccBlockTable] = useState([])
    const [selectedReportName, setSelectedReportName] = useState('')

    const crStatusColumns = ["CR ID", `CR Text`,`CR Description`,`CR Status`,`Days since last change`,`BP ID`,`Approved GAC`,`Pending GAC`,`CR Notes`]
    const erpReplicationCols = [`BP ID`,`SAP System`,`ECC Customer Number`,`Replication Error`,`ECC Vendor Number`,`Replication Error`]
    const slpReplicationCols = [`BP ID`, `ARIBA ID`,`ARIBA Errors`,`ACM ID`,`R4B Status`, `Registration Status`,`Qualification Status`]
    const eccBlockColumns = [`BP ID`, `SAP System`,`Mandt`,`Vendor Number`, `LFA1 Blocks`, `LFA1 Company Code` ,`LFB1 Block`, `Customer Number`, `KNA1 Block`,` KNB1 Company Code` ,`KNB1 Block`]



    const handleExport=(sheetName)=>{
        
        switch(sheetName){
            case "CR Status":
                exportToExcel(crStatusTableData, crStatusColumns, sheetName, searchInput)
                break;
            case "ERP Status":
                exportToExcel(replicationStatusTableData, erpReplicationCols, sheetName, searchInput)
                break;
            case "SLP Status":
                exportToExcel(slpReplicationTable, slpReplicationCols, sheetName, searchInput)
                break;
            case "ECC Blocks":
                exportToExcel(eccBlockTable, eccBlockColumns, sheetName, searchInput)
                break;
            default:
                return
        }


    }

    //handle the submit button
    const handleSubmit = async () => {
        
        setLoading(true)
        setSuccessMessage(false)
        setCrStatusTableData([])
        setReplicationStatusTableData([])
        setSlpReplicationTable([])
        setShowDataTable1(false)
        setShowDataTable2(false)
        setShowDataTable3(false)
        setShowDataTable4(false)


        if (!selectedReportName){
            setWarningMessage('Select dropdown type')
            setShowWarningMessage(true)
            setLoading(false)
            return
        }

        if (!searchInput || searchInput.length < 3) {
            setWarningMessage('CR need to be at least 3 characters')
            setShowWarningMessage(true)
            setLoading(false)
            return
        } else {
            setShowWarningMessage(false)
        }

        axios_cr_status_requests(searchInput,selectedReportName, 'crStatus').then(data =>{
            if (data.error){
                console.log("error cr status")
                console.log(setErrorMessage)
            }

            if (data.data.length > 0){
                setCrStatusTableData(data.data)
            }else{
                setCrStatusTableData([{"USMD_CREQUEST" : "Not Found"}])
            }
            setShowDataTable1(true)
        })

        axios_cr_status_requests(searchInput,selectedReportName, 'erpReplication').then(data =>{
            if (data.error){
                console.log("error erp status")
            }
            if (data.data.length > 0){
                setReplicationStatusTableData(data.data)
            }else{
                setReplicationStatusTableData([{"BP_ID": "Not found"}])
            }
            setShowDataTable2(true)
        })

        axios_cr_status_requests(searchInput,selectedReportName, 'slpReplication').then(data =>{
            if (data.error){
                console.log("error slp status")
            }
            if (data.data.length > 0){
                setSlpReplicationTable(data.data)
            }else{
                setSlpReplicationTable([{"BP_ID" : "Not Extended to SLP"}])
            }
            setShowDataTable3(true)
        })

        axios_cr_status_requests(searchInput,selectedReportName, 'eccBlocks').then(data =>{
            if (data.error){
                console.log("error slp status")
            }
            if (data.data.length > 0){
                setCccBlockTable(data.data)
            }else{
                setCccBlockTable([{"BP_ID" : "Nothing Found"}])
            }
            setShowDataTable4(true)
        })

        
    }


    useEffect(()=>{
        if (showDataTable1 && showDataTable1 && showDataTable1){
            setLoading(false)   
        }
    },[showDataTable1, showDataTable1, showDataTable1])


    return (
        <div>

            <div>
                <Navbar activePage={"/md_dashboard"} />
            </div>


            <div className="min-h-full flex flex-col justify-center py-8 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">MDG end to end search</h1>
                </div>

                {successMessage ? <div className="w-1/4 m-auto"><SuccessAlertV2 title={'Export complete'} /></div> : <></>}
                {errorMessage ? <div className="w-1/4 m-auto"><WarningAlertV2 title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div> : <></>}
                {showWarningMessage ? <div className="w-1/4 m-auto"><WarningAlertV2 title={warningMessage} subtitle={''} /></div> : <></>}


                <div className="mt-8 sm:mx-auto shadow sm:w-full sm:max-w-md">

                    <div className="flex flex-col items-center mt-4 mb-6 gap-6">
                        <SearchTypeDropdown setSelectedReportName={setSelectedReportName} />
                        <InputField setSearchInput={setSearchInput} selectedReportName={selectedReportName} />
                    </div>

                    <div className="mb-12 flex justify-center m-auto">
                        {loading ?
                            <button
                                type="button"
                                className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                                disabled
                            >
                                Please Wait
                            </button>
                            :
                            <button
                                type="button"
                                className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={handleSubmit}
                            >
                                Search
                            </button>

                        }
                    </div>
                </div>

            </div>

            <div>
                {loading ?
                    <LoadingSpinner />
                    :
                    <></>
                }
            </div>


            
                <did>
                    {showDataTable1 ? 
                    <div className="w-2/3 m-auto mb-4">
                        <h1 className="font-semibold text-3xl text-center">CR Status</h1>
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={()=>handleExport("CR Status")}
                        > Export to excel</button>
                        <ReportTable data={crStatusTableData} columnHeaders={crStatusColumns}  />
                    </div>
                    : <></>}


                    <div className="flex items-start gap-6 px-6">
                    {showDataTable2 ? 
                        <div className="w-[%40] mx-auto">
                            <h1 className="font-semibold text-3xl text-center">Replication Status - ERP</h1>
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={()=>handleExport("ERP Status")}
                            > Export to excel</button>
                            <ReportTable data={replicationStatusTableData} columnHeaders={erpReplicationCols}   />
                        </div>
                    :<></>}



                    {showDataTable3 && showDataTable4 ?
                        <div className="flex-col w-[%40]">
                        {showDataTable3 ? 
                            <div className="w-[%40] mx-auto">
                            <h1 className="font-semibold text-3xl text-center">Replication Status - SLP</h1>
                            <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={()=>handleExport("SLP Status")}
                                > Export to excel</button>
                                <ReportTable data={slpReplicationTable} columnHeaders={slpReplicationCols}   />
                            </div>
                        : <></>}

                        
                        {showDataTable4 ? 
                            <div className="w-[%40] mx-auto">
                            <h1 className="font-semibold text-3xl text-center">ECC LFA/B & KNA/B Blocks</h1>
                            <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={()=>handleExport("ECC Blocks")}
                                > Export to excel</button>
                                <ReportTable data={eccBlockTable} columnHeaders={eccBlockColumns}   />
                            </div>
                        : <></>}




                        </div>
                    : <></>}
                    </div>
                    
                </did>

     

            <div>
                <GenericFooter />
            </div>
        </div>
    )
}


