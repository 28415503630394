import React, {useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axiosOpenInvoiceEvent,axios_open_invoice_poll } from 'pages/apDashboard/pages/openInvoiceV2/support_functions/axiosRequest'
import UserMenu from 'pages/apDashboard/pages/openInvoiceV2/components/userMenu'
import InputField from 'pages/apDashboard/pages/openInvoiceV2/components/inputField'
import GenerticFooter from 'components/navigation/footer/GenericFooter'

import WarningAlertV2 from "components/alert/WarningAlertV2";
import SuccessAlertV2 from "components/alert/SuccessAlertV2";


export default function OpenInvoiceV2(){
    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [searchInput, setSearchInput]= useState('')
    const [selectedSapSystems, setSelectedSapSystems] = useState(null)
    const [selectedSearchField, setSelectedSearchField] = useState([])
    const [selectedClearedStatus, setSelectedClearedStatus] = useState([])
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(new Date().setFullYear( new Date().getFullYear() - 7)));
    const[ fileUrl, setFileUrl] = useState("")
    const[ processUUID, setProcessUUID] = useState()
    
    const startPolling = (uuid, pollCounter)=>{
        if (pollCounter === 14){
            setErrorMessage(true)
            setLoading(false)
            clearTimeout(startPolling)
            return 
        }
            axios_open_invoice_poll(uuid).then(data =>{
                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }
                if (!data.data.processing){
                    setFileUrl(data.data.fileUrl)
                    clearTimeout(startPolling)
                    return
                }
                setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
            })
    }


    //handle the submit button
    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)


        if (searchInput === ''){
            error = true
            setWarningMessage('You forgot to search for something')
            setShowWarningMessage(true)
        }else{
            setShowWarningMessage(false)
        }


        if (error === false){
            setLoading(true)
            axiosOpenInvoiceEvent(selectedSapSystems, selectedSearchField, selectedClearedStatus, searchInput,startDate, endDate).then( data =>{
                if (data.error){
                    setErrorMessage(true)
                    setLoading(false)
                    return
                }
                setProcessUUID(data.data.uuid)
                return
            })
        }else{
            setLoading(false)
            
            return null
            
        }
    }




    useEffect(()=>{
        if(processUUID){
            startPolling(processUUID, 0)
        }
    },[processUUID])


    useEffect(()=>{
        if (fileUrl.length > 0){
            window.open(fileUrl, "_blank")
            setLoading(false)
            setSuccessMessage(true)
        }
    },[fileUrl])
    


    return (
        <div>

            <div>
                <Navbar activePage="/ap_dashboard"/>
            </div>

            {successMessage ?<SuccessAlertV2 title={'Export complete'} subtitle={"Ensure Pop-ups are allowed"} />: <></>}
            {errorMessage ?<WarningAlertV2 title={'There was an error'} subtitle={'Contact finca@siemens-energy.com for help'} />: <></>}
            {showWarningMessage ?<WarningAlertV2 title={warningMessage} subtitle={''} />: <></>}


            <div className="flex flex-col justify-center py-8 sm:px-6 lg:px-8 ">

                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h1 className="mt-4 text-center text-3xl font-extrabold text-gray-900">Open Invoice Report</h1>
                </div>
                

                <div className="min-w-[33%] mt-8 shadow-md sm:w-full sm:max-w-md sm:mx-auto">

                <div className="mt-4 mb-6">
                    <UserMenu selectedSapSystems={selectedSapSystems}  setSelectedSapSystems={setSelectedSapSystems} 
                    selectedSearchField={selectedSearchField} setSelectedSearchField={setSelectedSearchField}
                    selectedClearedStatus={selectedClearedStatus} setSelectedClearedStatus={setSelectedClearedStatus}
                    startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} 
                        />
                </div>

                

                <div className="mt-4 mb-6 flex justify-center">
                    <InputField setSearchInput={setSearchInput} />
                    
                </div>

                    <div className="mb-4 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Search
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>
          
            
            <div>
                <GenerticFooter />
            </div>
      </div>
    )
}


