
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_get_scheduler_status = async  () =>{

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'rpt_classification/email_scheduler/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){
            return { error : false, data : response.data}
        }else{
            return { error : true}
        }

    }catch(e){
        return { error : true}
      
    }
}

export const axios_set_scheduler_status = async  (enable ) =>{

    var data = {'enabled' : enable}

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'rpt_classification/email_scheduler/',
            headers: { 'content-type': 'application/json' },
            params: data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {error : false}
        }else{
            return { error : true}
        }

    }catch(e){
        return { error : true}
      
    }
}