
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const axiosGetRPTUserPermissions = async (gid) => {

    let requestData = {
        'gid' : gid
    }
    

    try{
        
        const resp = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'rpt_user_management/permissions/',
            headers: { 'content-type': 'application/json' },
            params : requestData,
            withCredentials: true,
        })
        if (resp.status === 200){
            return {data: resp.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export const axiosGetAllRPTPermissions = async () => {

    try{
        
        const resp = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'rpt_user_management/permissions/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        })
        if (resp.status === 200){
            return {data: resp.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}