import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateFilter(props) {

  return (
    
    <div className='mx-auto flex gap-2 justify-center'>
        <div>
            <DatePicker
                className='border rounded-md p-2 text-center font-semibold'
                dateFormat="dd/MM/yyyy"
                selected={props.startDate}
                onChange={(date) => props.setStartDate(date)}
            />

            <div className=''>
                <p className='text-center'>Date From</p>
                <p className='text-center'>(dd/mm/yyyy)</p>
            </div>
        </div>

        <div>
            <DatePicker
                className='border rounded-md p-2 text-center font-semibold'
                dateFormat="dd/MM/yyyy"
                selected={props.endDate}
                onChange={(date) => props.setEndDate(date)}
            />

            <div className=''>
                <p className='text-center'>Date To</p>
                <p className='text-center'>(dd/mm/yyyy)</p>
            </div>
        </div>

    </div>
  )
}
