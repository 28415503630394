import React from 'react'
import Navbar from '../../components/navigation/navbar/Navbar'
import FunctionalitySelections from './FunctionalitySelection'


export default function RptDashboard() {
    return (
        <div>
            <div>
                <Navbar activePage="/rpt"/>
            </div>
            
            <div className="bg-white">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                    <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                        Related Party Transactions
                    </p>
                    <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
                        One stop shop for RPT related reports
                    </p>
                    </div>
                </div>
            </div>

            <div className='w-10/12 m-auto'>
                <FunctionalitySelections />
            </div>





        </div>
    )
}
