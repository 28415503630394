import React, {useState} from 'react'
import ConfirmationModals from './confirmationModals'
import { useSelector } from 'react-redux'

export default function PurchaseOrderView(props) {
    const [showMoldar, setShowMoldar] = useState(false)
    const [entryPrimayKey, setEntryPrimarykey] = useState({})
    const [loadingHistory, setLoadingHistory] = useState(false)
    const [submittedEbeln, setSubmittedEbeln] = useState([])
    const userGID = useSelector(state => state.user.user.gid)

    const handleClassify = (system, mandt, ebeln)=>{
        setShowMoldar(true)
        setEntryPrimarykey({system: system, mandt: mandt, ebeln: ebeln, user: userGID})
    }

    const openInNewTab = () => {
      const newWindow = window.open(props.poUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
      setLoadingHistory(false)
    }

    const exportToExcel = () =>{
      setLoadingHistory(true)
      openInNewTab()
    }
    
  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-2">
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">Purchase Orders</h1>
        <p className="mt-2 text-sm text-gray-700">
        Click <b>Classify</b> to classify the Purchase Orders
        </p>
      </div>
      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        {loadingHistory ? 
            <button
            type="button"
            className="block rounded-md bg-indigo-200 px-3 py-2 text-center text-sm cursor-progress font-semibold text-black shadow-smfocus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            
          >
            Please wait
          </button>
        :
        <button
        type="button"
        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={exportToExcel}
      >
        Export to Excel
      </button>
      }
      </div>
    </div>
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300 overflow-auto">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-3">
                  SAP System
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Mandt
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  ARE
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  ARE Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Company Code
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Depth Structure
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Vendor Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Vendor Number
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Trading Partner
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Vendor IFA 
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  PO Number
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Creation Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Currency
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  PO Amount
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  PO Creator
                </th>
                <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                  Classify PO
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {props.poRows.map((i, index) => (
                <tr key={index} className="even:bg-gray-50">
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_SOURSYS}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_MANDT} </td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.T001_RCOMP}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.T001_BUTXT}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_BUKRS}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.DEPTH_STRUCTURE}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_NAME1}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_LIFNR}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_VBUND}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.LFA1_CMD_RES_12}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_EBELN}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_ERDAT}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_WAERS}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKPO_NETWR}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">{i.EKKO_ERNAM}</td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-md font-semibold sm:pr-3">
                    {submittedEbeln.includes(i.EKKO_EBELN) ? <td className="whitespace-nowrap px-3 py-4 text-center text-sm text-gray-500">Submitted</td> :
                      <a onClick={()=>handleClassify(i.EKKO_SOURSYS, i.EKKO_MANDT, i.EKKO_EBELN)} className="text-indigo-600 hover:text-indigo-900 p-2 rounded-md bg-indigo-200 cursor-pointer">
                        Classify<span className="sr-only">, {}</span>
                      </a>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>


                
    <div className=''>
      {showMoldar 
      ?  <ConfirmationModals showMoldar={showMoldar} setShowMoldar={setShowMoldar} entryPrimayKey={entryPrimayKey}
      submittedEbeln={submittedEbeln}  setSubmittedEbeln={setSubmittedEbeln} /> 
      : <></>}
       
    </div>
        

  </div>
  )
}

