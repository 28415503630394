
import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import ModalsSwitchFirstQuestion from './modalsSwitchFirstQuestion'
import ModalSwitchSecondQuestion from './modalSwitchSecondQuestion'
import WarningAlert from 'components/alert/WarningAlert'
import { axios_post_sales_document_power_user } from '../../support_functions/axiosRegisterPowerUser'

export default function ConfirmationModals(props) {
    const cancelButtonRef = useRef(null)
    const [question1, setquestion1] = useState()
    const [question2, setquestion2] = useState()
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [inputValue, setInputValue] = useState("");

    const handleCancel = ()=>{
      props.setShowMoldar(false)      
    }

    const onChangeHandler = (e) => {
      setInputValue(e.target.value);
   };
    
    const handleSave = ()=>{
      if (showSuccess){
        return
      }

      setLoading(true)
      const data = {market_conditions: question2, ordinary_course:question1, mandt : props.entryPrimayKey.mandt
        , system : props.entryPrimayKey.system, user : props.entryPrimayKey.user,  vbeln : props.entryPrimayKey.vbeln, 
        note :  inputValue
      }

      axios_post_sales_document_power_user(data).then(data =>{
        setLoading(false)
        if (data.error){
          setShowError(true)
          return
        }

        setShowSuccess(true)
        setTimeout(handleCancel, 2000);
        props.setSubmittedVbeln([...props.submittedVbeln, props.entryPrimayKey.vbeln ])

      })

    }

  return (
    <Transition.Root show={props.showMoldar} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>

                {showSuccess ? 
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  : 
                  <></>
                }

                {showError ? 
                
                <div className="mx-auto flex items-center">
                  <WarningAlert title={"Error registering response, contact RPT Team"} />
                </div>
                :
                <></>              
              }

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Related Party Transaction
                      <br/>
                       Power User Classification
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        <ModalsSwitchFirstQuestion vbeln={props.entryPrimayKey.vbeln} setResponse={setquestion1} />
                      </p>

                      <p className="text-sm text-gray-500 mt-4">
                        <ModalSwitchSecondQuestion vbeln={props.entryPrimayKey.vbeln}  setResponse={setquestion2} />
                      </p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <p className='text-sm font-semibold'>If you dont know what you are doing, time to leave this page.</p>          
                  </div>
                    <div>
                      <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
                        Power User Note
                      </label>
                      <div className="mt-2 w-full mx-auto">
                        <input
                          type="text"
                          name="text"
                          id="text"
                          className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="Make a note to help document this decision."
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                </div>
                {loading 
              ?

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-200 px-3 py-2 text-sm font-semibold cursor-progress text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    
                  >
                    Please wait
                  </button>

                </div>

                :

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => handleSave()}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => handleCancel()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                </div>
                }

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
