import React, {useState} from 'react'
import PowerUserFilterSelections from './components/powerUserFilterSelections';


export default function PowerUserFilter(props) {

    const [filter1DateStart, setFilter1DateStart] = useState(new Date(new Date().setFullYear( new Date().getFullYear() - 1)));
    const [filter1DateEnd, setFilter1DateEnd] = useState(new Date());
    const [selectedFilter1, setSelectedFilter1] = useState('')
    const [enabledFilter1, setEnabledFilter1] = useState(false)
    const [textSearch1, setTextSearch1] = useState('')

    const [filter2DateStart, setFilter2DateStart] = useState(new Date(new Date().setFullYear( new Date().getFullYear() - 1)));
    const [filter2DateEnd, setFilter2DateEnd] = useState(new Date());
    const [selectedFilter2, setSelectedFilter2] = useState('')
    const [enabledFilter2, setEnabledFilter2] = useState(false)
    const [textSearch2, setTextSearch2] = useState('')


    const [filter3DateStart, setFilter3DateStart] = useState(new Date(new Date().setFullYear( new Date().getFullYear() - 1)));
    const [filter3DateEnd, setFilter3DateEnd] = useState(new Date());
    const [selectedFilter3, setSelectedFilter3] = useState('')
    const [enabledFilter3, setEnabledFilter3] = useState(false)
    const [textSearch3, setTextSearch3] = useState('')


    const handleSubmit = ()=>{


        let data ={
            filter1DateStart: convertDate(filter1DateStart),
            filter1DateEnd: convertDate(filter1DateEnd),
            selectedFilter1: selectedFilter1,
            enabledFilter1: enabledFilter1,
            textSearch1: textSearch1,
            filter2DateStart: convertDate(filter2DateStart),
            filter2DateEnd:  convertDate(filter2DateEnd),
            selectedFilter2:  selectedFilter2,
            enabledFilter2: enabledFilter2,
            textSearch2: textSearch2,
            filter3DateStart: convertDate(filter3DateStart),
            filter3DateEnd: convertDate(filter3DateEnd),
            selectedFilter3: selectedFilter3,
            enabledFilter3: enabledFilter3,
            textSearch3: textSearch3,
                }

        props.setPowerUserSelections(data)
    }

  return (
    <div className='flex gap-2'>
        <PowerUserFilterSelections
        id={1}
        loading={props.loading}
        startDate={filter1DateStart}
        setStartDate={setFilter1DateStart}
        endDate={filter1DateEnd}
        setEndDate={setFilter1DateEnd}
        selectedFilter={selectedFilter1}
        setSelectedFilter={setSelectedFilter1}
        setTextSearch={setTextSearch1}
        enabled={enabledFilter1}
        setEnabled={setEnabledFilter1}
        handleSubmit={handleSubmit}
        />

<PowerUserFilterSelections
        id={2}
        loading={props.loading}
        startDate={filter2DateStart}
        setStartDate={setFilter2DateStart}
        endDate={filter2DateEnd}
        setEndDate={setFilter2DateEnd}
        selectedFilter={selectedFilter2}
        setSelectedFilter={setSelectedFilter2}
        setTextSearch={setTextSearch2}
        enabled={enabledFilter2}
        setEnabled={setEnabledFilter2}
        handleSubmit={handleSubmit}
        />

<PowerUserFilterSelections
        id={3}
        loading={props.loading}
        startDate={filter3DateStart}
        setStartDate={setFilter3DateStart}
        endDate={filter3DateEnd}
        setEndDate={setFilter3DateEnd}
        selectedFilter={selectedFilter3}
        setSelectedFilter={setSelectedFilter3}
        setTextSearch={setTextSearch3}
        enabled={enabledFilter3}
        setEnabled={setEnabledFilter3}
        handleSubmit={handleSubmit}
        />
    </div>
  )
}


const convertDate = (datepickerValue) =>{
    let year = datepickerValue.getFullYear()
    let month = datepickerValue.getMonth() + 1
    let day = datepickerValue.getDate()

    let formattedString = day + "/" + month + "/" + year 
    return (formattedString)
}