import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/solid'

const filters = [

  { id: 0, filterName: 'Select Filter'},
  { id: 1, filterName: 'Creation Date Range' },
  { id: 2, filterName: 'PO/SD Creator (GID)' },
  { id: 3, filterName: 'Normal Course (yes/no)' },
  { id: 4, filterName: 'Market Conditions (yes/no)' },
  { id: 5, filterName: 'User Response Date' },
  { id: 6, filterName: 'Download everything' },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function FilterMenu(props) {
    const [selected, setSelected] = useState(filters[0])

    const handleChange = (e)=>{
      setSelected(e)
      if (e.filterName == filters[0].filterName){
        props.setSelectedFilter('')
        return
      }

      props.setSelectedFilter(e.filterName)

    }

    return (
      <Listbox value={selected} onChange={handleChange}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-lg font-medium leading-6 text-gray-900">Filter Option {props.id}</Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">{selected.filterName}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
  
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filters.map((filterOption) => (
                    <Listbox.Option
                      key={filterOption.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={filterOption}
                    >
                      {({ selected, active }) => (
                        <>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {filterOption.filterName}
                          </span>
  
                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}

      </Listbox>
    )
  }