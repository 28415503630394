import axios from 'axios'

import {sapDashboardAPIBaseuri} from 'globals/variables/axiosVariables'

/////////////////////////// convert session token into user data
export default async function  ConvertSessionToRptUserData(){

    try{
        const response = await axios({
            method: "GET",
            url: sapDashboardAPIBaseuri + "rpt_verify_user/",
            headers: { 
                'content-type': 'application/json' ,
            },
            mode: 'same-origin',
            withCredentials: true,
        })

        if (response.status == 200){
            return  response.data;
        }


    }catch(e){
        return null
    }

}
