
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_get_po_records = async  (gid ) =>{
    let parms = {gid : gid}

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'rpt_classification/get_purchase_orders/',
            headers: { 'content-type': 'application/json' },
            params: parms,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
      
    }
}

export const axios_get_sales_records = async  (gid) =>{

    let parms = {gid : gid}

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'rpt_classification/get_sales_orders/',
            headers: { 'content-type': 'application/json' },
            params: parms,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}



export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}
