import React, {useState, useEffect} from "react";
import Navbar from "components/navigation/navbar/Navbar";
import LoadingSpinner from "components/loading/LoadingSpinner";
import {axios_fpa_po_status_reports, downloadJsonContents } from "./support_functions/axiosRequest"
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import ReportTable from 'components/reportTable/reportTable'
import InputField from "./components/inputField"
import GenericFooter from "components/navigation/footer/GenericFooter";




export default function FpaPoStatus(){

    const [loading, setLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showWarningMessage, setShowWarningMessage] = useState(false)
    const [showDataTable, setShowDataTable] = useState(false)
    const [tableData, setTableData] = useState([])
    const defaultColumnHeaders = [`SAP System`, `Client`, `Purchase Order Group`, `PO Number`, `PO Item Number`, `Text`, `Vendor Number`, `Vendor Name`, `VBUND`, `PO Doc Date`, `PO Creation Date`, `Menge`, `Meins`, `PO Currency`, `Net Price`, `PeinH `, `Plant`, `Potential Reversal`, `Item Received GR`, `PO Deletion Indicator`, `PO Item Deleteion Indicator `,`Invoiced Amount` ,`FI Document Number`, `Profit Center `, `Depth Structure (latest)`]
    const [searchInput, setSearchInput]= useState('')
    const [fileUrl, setFileUrl] = useState("")
    const [jsonUrl,setJsonUrl] = useState("")

    //export the data to excel 
    const exportDataToExcel = () =>{
        window.open(fileUrl, "_blank")
    }


    //handle the submit button
    const handleSubmit = async () =>{
        let error = false
        setLoading(false)
        setSuccessMessage(false)
        setShowDataTable(false)

        if (searchInput === ''){
            error = true
            setWarningMessage('You forgot to insert a PO number')
            setShowWarningMessage(true)
        }else{
            setShowWarningMessage(false)
        }

        if (error === false){
            setLoading(true)
            axios_fpa_po_status_reports(searchInput).then( data =>{
                    if (data.error){
                        setErrorMessage(true)
                        setLoading(false)
                        return
                    }
                    setFileUrl(data.data.fileUrl)
                    setJsonUrl(data.data.jsonUrl)
                })
        }else{
            return null
            
        }
    }

    // wait until the table data is updated in use state before shwoing table
    useEffect(() =>{
        if (tableData.length !== 0){
            setLoading(false)        
            setShowDataTable(true)
            return
        }
    },[tableData])

    useEffect(()=>{
        if (jsonUrl){
            downloadJsonContents(jsonUrl).then(data =>{
                setTableData(data)
            })
        }
    },[jsonUrl])

    


    return (
        <div>

            <div>
                <Navbar activePage="/ac_operations"/>
            </div>


            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
                <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                    <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Purchase Order Status Report</h1>
                    <h1 className="mt-1 text-center text-xl font-semibold text-gray-900">Insert a PO Number(s)</h1>
                </div>
                
                {successMessage ?<div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div>: <></>}
                {errorMessage ?<div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div>: <></>}
                {showWarningMessage ?<div className="w-1/4 m-auto"><WarningAlert title={warningMessage} subtitle={''} /></div>: <></>}



            
                <div className="mt-8 sm:mx-auto shadow-md sm:w-full sm:max-w-md">

                <div className="flex justify-center mt-4 mb-6">
                    <InputField setSearchInput={setSearchInput} />
                </div>

                


                    <div className="mb-12 flex justify-center m-auto">
                    {loading ? 
                                            <button
                            type="button"
                            className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                        >
                            Please Wait
                        </button>
                    
                    :
                        <button
                            type="button"
                            className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={handleSubmit}
                        >
                            Search
                        </button>

                    }
                    </div>
                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            {showDataTable ? 
                <div className="w-2/3 m-auto mb-24">
                          <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() =>exportDataToExcel()}
                            > Export to excel</button>



                    <ReportTable data={tableData} columnHeaders={defaultColumnHeaders}  />
                </div>

                :
                <></>
            }

            <div>
                <GenericFooter />
            </div>
      </div>
    )
}


