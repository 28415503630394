
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_cr_status_requests = async  (searchInput,selectedReportName, reportName ) =>{

    let requestData ={
        'searchInput' : searchInput,
        'searchType' : selectedReportName,
    }

    let requestUri;
    switch (reportName){

        case 'crStatus':
            requestUri = "md_dashboard/e2e/crStatus/"
            break;
        case 'erpReplication':
            requestUri = "md_dashboard/e2e/erpReplication/"
            break;
        case 'slpReplication':
            requestUri = "md_dashboard/e2e/slpReplication/"
            break; 
        case 'eccBlocks':
            requestUri = "md_dashboard/e2e/ecc_blocks/"
            break;
        default:
            console.log("error with request")
            return
    }


    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + requestUri,
            headers: { 'content-type': 'application/json' },
            params : requestData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


