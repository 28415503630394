import React, {useState} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SingleSelectDropdownV2 from './singleSelectDropdownV2';



export default  function UserMenu(props) {


    const [showDateFields, setShowDateFields] = useState(false)


      const clearedDropdownV2 = [
        {id: 1, value: null, text: 'All' },
        {id: 2, value: "CLEARED", text: 'Cleared'},
        {id: 3, value: "UNCLEARED", text: 'Uncleared'}
      ]



      const searchFieldDropdownV2 = [
        {id: 1, value: "REF_DOC_NO", text: 'Reference Number' },
        {id: 2, value: "VENDOR_NUMBER", text: 'Vendor Number'},
        {id: 3, value: "PO_NUMBER", text: 'PO Number'},
        {id: 4, value: "VBUND", text: "VBUND"},
        {id: 5, value: "CLEARING_DOCUMENT", text: "Clearing Document"},
        {id: 6, value: "IFA", text: "IFA"},
      ]

  const systemSelectionsV2 = [
    {id: 0, value: null, text: "All"},
    {id: 1, value: "MPP01", text: "MPP01"},
    {id: 2, value: "REP01", text: "REP01"},
    {id: 3, value: "KSP01", text: "KSP01"},
    {id: 4, value: "OPP01", text: "OPP01"},
    {id: 5, value: "PS102", text: "PS102"},
    {id: 6, value: "PTG01", text: "PTG01"},
    {id: 7, value: "FNP01", text: "FNP01"},
    {id: 8, value: "P5E01", text: "P5E01"},
    {id: 9, value: "S4E01", text: "S4E01"}
]





  return (
        <div>

            <div className='mb-4 flex justify-around'>
                <div className='flex flex-col'>
                <div className='min-w-14'>
                        <SingleSelectDropdownV2 selected={props.selectedSapSystems} setState={props.setSelectedSapSystems}  label={"SAP Systems"} dropDownSelections={systemSelectionsV2}/>
                 </div>

                    <div className='flex flex-col justify-center mt-4 text-md font-medium min-w-14'>
                        <label className='mb-1'> Change Date Range</label>
                        <input type="checkbox" className='h-6 w-6 mx-auto' checked={showDateFields} onChange={() =>setShowDateFields(!showDateFields)}/>
                    </div>
                </div>

                <div className='flex flex-col'>
                    <div className='min-w-14'>
                        <SingleSelectDropdownV2 selected={props.selectedSearchField} setState={props.setSelectedSearchField}  label={"Search Field"} dropDownSelections={searchFieldDropdownV2}/>
                    </div>
                    <div className='mt-3 min-w-14'>
                        <SingleSelectDropdownV2 selected={props.selectedClearedStatus} setState={props.setSelectedClearedStatus}  label={"Cleared Status"} dropDownSelections={clearedDropdownV2}/>
                    </div>
                </div>
            </div>



            {showDateFields ?
                <div className='flex justify-around'>
                    <div className='max-w-[50%]'>
                            <DatePicker
                                className='border mt-6 text-center p-1'
                                dateFormat="dd/MM/yyyy"
                                selected={props.startDate}
                                onChange={(date) => props.setStartDate(date)}
                            />

                            <div className='text-center'>
                                Date From <br/> (dd/mm/yyyy)
                            </div>

                        </div>


                        <div className='max-w-[50%]'>
                                <DatePicker
                                    className='border mt-6  text-center p-1 rounded-sm'
                                    dateFormat="dd/MM/yyyy"
                                    selected={props.endDate}
                                    onChange={(date) => props.setEndDate(date)}
                                />

                                <div className='text-center'>
                                    Date To <br/> (dd/mm/yyyy)
                                </div>
                        </div>
                </div>

                :
                <>
                </>
            }
        </div>
  )
}

