import React, {useState, useEffect} from 'react'
import ConfirmationModals from './confirmationModals'
import { useSelector } from 'react-redux'
import DisplayTable from './displayTable'

export default function PurchaseOrderView(props) {
    const [showMoldar, setShowMoldar] = useState(false)
    const [entryPrimayKey, setEntryPrimarykey] = useState({})
    const [loadingHistory, setLoadingHistory] = useState(false)
    const [submittedEbeln, setSubmittedEbeln] = useState([])
    const [displayedRows, setDisplayRows] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [propsRows, setPropsRows] = useState(0)
    const userGID = useSelector(state => state.user.user.gid)

    const handleClassify = (system, mandt, ebeln)=>{
        setShowMoldar(true)
        setEntryPrimarykey({system: system, mandt: mandt, ebeln: ebeln, user: userGID})
    }

    const openInNewTab = () => {
      const newWindow = window.open(props.poUrl, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
      setLoadingHistory(false)
    }

    const exportToExcel = () =>{
      setLoadingHistory(true)
      openInNewTab()
    }


    const handleNaviation = (selection) =>{

      //dont change if the page is the first or last
      if (selection === 'back' && currentPage === 1){
          return 
      }
      if (selection === 'next' && propsRows < (currentPage * 10) ){
          return 
      }
      if (selection === 'back'){
          setCurrentPage(currentPage - 1)
      }else if (selection === 'next'){
        setCurrentPage(currentPage + 1)
      }
      
  }
    

    useEffect(() =>{
      setDisplayRows(props.poRows.slice((currentPage - 1) * 10, ((currentPage - 1) * 10) + 10))
    },[currentPage])



    useEffect(()=>{
      if (props.poRows){
        setPropsRows(props.poRows.length)
        setDisplayRows(props.poRows.slice(0,9))
      }
    },[])


  return (
    <div className={`${props.show ? 'opacity-100' : 'opacity-0'} duration-75 transition-opacity ease-in-out px-4 sm:px-6 lg:px-8 mt-2`}>
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-base font-semibold leading-6 text-gray-900">Purchase Orders ({props.poRows.length} Entires)</h1>
        <p className="mt-2 text-sm text-gray-700">
        Click <b>Classify</b> to classify the Purchase Orders
        </p>
      </div>
      <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        {loadingHistory ? 
            <button
            type="button"
            className="block rounded-md bg-indigo-200 px-3 py-2 text-center text-sm cursor-progress font-semibold text-black shadow-smfocus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            
          >
            Please wait
          </button>
        :
        <button
        type="button"
        className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={exportToExcel}
      >
        Export to Excel
      </button>
      }
      </div>
    </div>
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

            <DisplayTable data={displayedRows} handleClassify={handleClassify} submittedEbeln={submittedEbeln} />


        </div>
      </div>
      
    </div>

    <div className='flex gap-4 justify-start mt-4'>
      <button
        type="button"
        className={`${currentPage === 1 ? "disabled opacity-0 cursor-default" : "opacity-100"} transition-opacity ease-in-out rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
        onClick={()=>handleNaviation('back')}
      >
        Last page
      </button>

      <button
        type="button"
        className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={()=>handleNaviation('next')}
      >
        Next page
      </button>
    </div>

                
    <div className=''>
      {showMoldar 
      ?  <ConfirmationModals showMoldar={showMoldar} setShowMoldar={setShowMoldar} entryPrimayKey={entryPrimayKey}
      submittedEbeln={submittedEbeln}  setSubmittedEbeln={setSubmittedEbeln} /> 
      : <></>}
       
    </div>
        

  </div>
  )
}

