import React from 'react'
import FilterMenu from './filterMenu'
import DateFilter from './dateFilter'
import TrueFalseSwitch from './trueFalseSwitch'

export default function PowerUserFilterSelections(props) {


  return (
    <div className=" bg-white shadow sm:rounded-lg w-1/4 mx-auto mb-2">
      <div className="px-4 py-5 sm:p-6"><FilterMenu id={props.id} setSelectedFilter={props.setSelectedFilter} /></div>
        <div>
          {props.selectedFilter.includes('Date')
            ?
            <DateFilter 
              
              startDate={props.startDate} setStartDate={props.setStartDate} 
              endDate={props.endDate} setEndDate={props.setEndDate} />
              :
              <></>
            }
        </div>

        {!props.selectedFilter.toLowerCase().includes('date') && props.selectedFilter &&
        !props.selectedFilter.toLowerCase().includes('yes') && 
        !props.selectedFilter.toLowerCase().includes('everything')  ?
          <div className='flex justify-center'>
            <input onChange={(e)=>props.setTextSearch(e.target.value )} type='text' placeholder='Insert text' className='input border font-medium mx-auto p-2 rounded-md w-2/3'></input>
          </div>
          :
          <></>
          }

          {props.selectedFilter.includes('yes') ?
          <div className="flex justify-center">
            <TrueFalseSwitch enabled={props.enabled} setEnabled={props.setEnabled}   />
          </div>
          :
          <></>
        }

          <div className='flex justify-center mt-2 mb-2'>
          {props.loading ? 
            <button
                  type="button"
                  className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                  disabled
              >
                  Please Wait
              </button>
          
            :
              <button
                  type="button"
                  className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={props.handleSubmit}
              >
                  Search
              </button>

            }
          </div>
    </div>
  )
}



