
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const axios_auditor_fees_report = async (selectedAre, yearFrom, periodFrom, yearTo, periodTo) =>{

    let requestData = {
        'searchAre' : selectedAre, 
        'yearFrom' :  yearFrom, 
        'periodFrom' : periodFrom, 
        'yearTo' : yearTo, 
        'periodTo' : periodTo
    }



    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/auditor_fees_report/',
            headers: { 'content-type': 'application/json' },
            params: requestData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const axios_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/auditor_fees_report/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

