
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const axiosUpdateRPTUserPermissions = async (gid, permissions) => {
    let data = {
        "gid": gid,
        "permissions": permissions,
    }

    try{
        const resp = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'rpt_user_management/create_user_permissions/',
            headers: { 'content-type': 'application/json' },
            data : data,
            withCredentials: true,
        })
        if (resp.status === 200){
            return {data: resp.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}