import React, {useState, useEffect, useRef} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import WarningAlert from 'components/alert/WarningAlert';
import SuccessAlert from 'components/alert/SuccessAlert';
import { AreSlections, PeriodSelections, EspritAccounts } from './components/defaultDropDowns'
//import UserSelections from './components/userSelections';
import { axios_espritAccount_search, axios_poll } from './support_functions/axiosRequest';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import GenericFooter from 'components/navigation/footer/GenericFooter';
import UserSelectionsV2 from './components/userSelectionsV2';

export default function EspritAccountSearch() {
    const [loading, setLoading] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [showSuccess, setShowSuccess] = useState(false)
    const [yearSelections, setYearSelections] = useState([])
    const [processUUID,setProcessUUID] = useState(null)
    const [fileUrl,setFileUrl] = useState('')

    const fromYearRef = useRef('')
    const fromMonthRef = useRef('')
    const toYearRef = useRef('')
    const toMonthRef = useRef('')
    const selectedAreRef = useRef('')
    const entireBalanceRef = useRef('')
    const espritAccountRef = useRef('')

    const handleSubmit=()=>{


        if (!AreSlections.includes(selectedAreRef.current.value)){
            setShowWarning(true)
            setWarningMessage("ARE not selected")
            return
        }

        
        if (!EspritAccounts.includes(espritAccountRef.current.value)){
            setWarningMessage("ESPRIT Account not selected")
            setShowWarning(true)
            return
        }


        if (!yearSelections.includes(toYearRef.current.value) || !PeriodSelections.includes(toMonthRef.current.value)){
            setWarningMessage("To period/year not selected")
            setShowWarning(true)
            return
        }


        if ((!yearSelections.includes(fromYearRef.current.value) && !PeriodSelections.includes(fromMonthRef.current.value)) && entireBalanceRef.current.checked === false ){
            setWarningMessage("From period/year not selected")
            setShowWarning(true)
            return
        }

        setLoading(true)
        setShowSuccess(false)
        setShowWarning(false)

        axios_espritAccount_search(
            selectedAreRef.current.value, 
            fromMonthRef.current.value, 
            fromYearRef.current.value,
            toMonthRef.current.value,
            toYearRef.current.value,
            espritAccountRef.current.value,
            entireBalanceRef.current.checked  ).then(data =>{
            if (data.error){
                setLoading(false)
                return
            }
            setProcessUUID(data.data.uuid)
            return
        })

    }


    const startPolling = (uuid, pollCounter)=>{
        if (pollCounter === 24){
            setShowWarning(true)
            setLoading(false)
            setWarningMessage("Poll timeout, this report took too long to run. Please try it again")
            clearTimeout(startPolling)
            return 
        }
        axios_poll(uuid).then(data =>{
                if (data.error){
                    setShowWarning(true)
                    setWarningMessage("Error polling data , contact Kevin")
                    setLoading(false)
                    return
                }
                if (!data.data.processing){
                    setFileUrl(data.data.fileUrl)
                    clearTimeout(startPolling)
                    return
                }
                setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
            })
    }



    useEffect(()=>{
        if(processUUID){
            startPolling(processUUID, 0)
        }
    },[processUUID])

    useEffect(()=>{
        if(fileUrl){
            setShowSuccess(true)
            window.open(fileUrl, "_blank")
            setLoading(false)   
        }
    },[fileUrl])

    useEffect(()=>{
        let date = new Date()

        if (date.getMonth() >= 9){
            setYearSelections([(date.getFullYear() + 1 ).toString(), date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }else{
            setYearSelections([date.getFullYear().toString(), (date.getFullYear() - 1).toString()])
        }
    },[])



    return (
        <div>
            <Navbar activePage="/ac_operations"/>
    
    
            <div className="mb-2 mt-2 flex justify-center m-auto">
    
                <div className="min-h-full flex flex-col justify-center py-4 sm:px-6 lg:px-8 m-4 shadow-md lg:w-1/3 sm:w-1/2 rounded-md mx-auto">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
                            <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Esprit Account Search</h1>
                            <div className='w-2/3 mx-auto mt-2'>
                                {showWarning ? <WarningAlert title={warningMessage} />:<></>}
                                {showSuccess ? <SuccessAlert title={"Download complete"} /> : <></>}
                            </div>
                        </div>
        
        
        
                        <div className='flex flex-col w-full'>
        
                        
                        <div className="lg:w-full md:w-2/3 bg-grey-lighter mx-auto flex justify-center">
                            <UserSelectionsV2 
                                fromYearRef ={fromYearRef}
                                fromMonthRef ={fromMonthRef}
                                toYearRef ={toYearRef}
                                toMonthRef ={toMonthRef}
                                selectedAreRef ={selectedAreRef}
                                entireBalanceRef= {entireBalanceRef}
                                espritAccountRef={espritAccountRef}
                                yearSelections={yearSelections}
                            />
                            
                        </div>
        
        

        
                    <div className='flex justify-center mt-4'>

                        {loading ? 
                            <button
                            type="button"
                            className="px-4 w-1/3 mx-auto mt-6 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                            disabled
                            >
                                Please Wait
                            </button>
                
                        :
                        <button onClick={handleSubmit} type="button" className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Start Download
                        </button>
                        }

                        </div>

                        </div>

                </div>

            </div>

            <div>
                {loading ? 
                    <LoadingSpinner />

                    :
                    <></>
                }
            </div>


            <div>
                <GenericFooter />
            </div>


        </div>
      )
    }
    