
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';


export const axios_espritAccount_search = async (          
    selectedAreRef, fromMonthRef, fromYearRef,
    toMonthRef,toYearRef,espritAccountRef,entireBalanceRef ) =>{


    let requestData = {
        selectedAre : selectedAreRef,
        selectedESPRITAccount :espritAccountRef, 
        monthFrom : fromMonthRef, 
        yearFrom : fromYearRef, 
        yearTo : toYearRef, 
        monthTo : toMonthRef, 
        entireBalance : entireBalanceRef
    }



    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/fpa_dashboard/esprit_account_reciever/',
            headers: { 'content-type': 'application/json' },
            params: requestData,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}


export const axios_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ac_operations/fpa_dashboard/esprit_account_reciever/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

