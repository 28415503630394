
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_send_reminder_emails = async  () =>{

    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'rpt_classification/reminder_email/',
            headers: { 'content-type': 'application/json' },
            withCredentials: true,
        });
        
        if (response.status === 200){
            return { error : false, data : response.data}
        }else{
            return { error : true}
        }

    }catch(e){
        return { error : true}
      
    }
}