
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

const convertDate = (datepickerValue) =>{
    let year = datepickerValue.getFullYear()
    let month = datepickerValue.getMonth() + 1
    let day = datepickerValue.getDate()

    let formattedString = day + "/" + month + "/" + year 
    return (formattedString)
}

export const axiosOpenInvoiceEvent = async  (selectedSapSystems, selectedSearchField, selectedClearedStatus, searchInput,startDate, endDate ) =>{


    let data ={
        'searchInput' : searchInput, 
        'systemData': selectedSapSystems,
        'searchField' : selectedSearchField,
        'searchClearedStatus' : selectedClearedStatus, 
        'startDate' : convertDate(startDate),
        'endDate' : convertDate(endDate),
    }


    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/open_invoice/v2/',
            headers: { 'content-type': 'application/json' },
            data : data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {data: response.data, error : false}
        }else{
            return {data: [], error : true}
        }

    }catch(e){
        return {data: [], error : true}
    }
}




export const axios_open_invoice_poll =async (uuid) =>{
    try{
        const response = await axios({
            method: 'GET',
            url: sapDashboardAPIBaseuri + 'ap_dashboard/open_invoice/poll/',
            headers: { 'content-type': 'application/json' },
            params: {processUUID : uuid},
            withCredentials: true,
        });
        
        if (response.status === 200 ){
            return {data: response.data, error : false}
            
        }else{
            return {data: response.data, error : false}
        }

    }catch(e){
        return {data: [], error : true}
    }
}

export const downloadJsonContents = async (urlPath) =>{
    const response = await axios({
        method: 'GET',
        url: urlPath,
        headers: { 'content-type': 'application/json' },
        responseType: 'application/json',
        transformResponse: (data) => JSON.parse(data),
    })
    if (response.status === 200){
        return response.data
    }
}
