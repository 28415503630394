import React, {useState, useEffect, useRef} from 'react'
import { axios_get_selected_fiscal_year, axios_set_selected_fiscal_year } from '../../../support_functions/axiosFiscalYears'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import SuccessAlertV2 from 'components/alert/SuccessAlertV2'

export default function FiscalYearSetting(props) {
const [fiscalYears, setFiscalYears] = useState([])
const [selectedFiscalYear, setSelectedFiscalYear] = useState()
const [loading, setLoading] = useState(false)
const [success, setSuccess] = useState(false)
const fiscalYearRef = useRef()


const handleSaveFiscalYear = ()=>{
  if (Number(fiscalYearRef.current.value)){
    const fiscalYear = fiscalYearRef.current.value
    setLoading(true)
    axios_set_selected_fiscal_year(fiscalYear).then(data =>{
      if(data.error){
        console.log(data)
      }
      setSuccess(true)
      setSelectedFiscalYear(fiscalYear)
      setLoading(false)
    })

  }
  
}
useEffect(()=>{
  if (!selectedFiscalYear){
    axios_get_selected_fiscal_year().then(data =>{
      props.setInitalFiscalYear(true)
      if (data.error){
        console.log(data)
        return
      }
      setSelectedFiscalYear(data.data.fiscalYear)
    })
  }

},[])





useEffect(()=>{
  var dt = new Date();
  var newDate = new Date(dt.setMonth(dt.getMonth()+3));
  let yearArray = []
  yearArray.push(newDate.getFullYear() - 1)
  yearArray.push(newDate.getFullYear())
  yearArray.push(newDate.getFullYear() + 1)
  setFiscalYears(yearArray)
},[])


  return (
    <div className='p-6'>

      {success ? <SuccessAlertV2 title={"Fiscal Year Updated"} /> : <></>}

      <div >
        <label htmlFor="location" className="block text-medium font-medium leading-6 text-gray-900">
         Set Fiscal Year
        </label>
        <div className='flex w-1/3'>

        {loading ?<div className='h-6 w-6'> <LoadingSpinner /> </div> : <></>}
          <div className={`${loading ? 'opacity-0' : 'opacity-100'} `}> 
          <select
            className="mt-2 w-52 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue=''
            ref={fiscalYearRef}
          >
            <option key={99}>{`Selected FY: ${selectedFiscalYear}`}</option>
            {fiscalYears.map((i, index) =>{
              return <option key={index}>{i}</option>
            })}


          </select>

          <button
            type="button"
            className="rounded-md ml-2 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSaveFiscalYear}
          >
            Save
          </button>
          </div>
          

        </div>
      </div>

      
   
</div>
  )
}
