import React, {useState, useEffect} from 'react'
import { axios_get_etl_refresh_status, axios_set_etl_refresh_status } from '../../../support_functions/axiosEtlDailyRefresh'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import { Switch } from '@headlessui/react'
import SuccessAlertV2 from 'components/alert/SuccessAlertV2'

export default function EtlDailyRefreshScheduler(props) {
    const [schedulerEnabled, setSchedulerEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleEmailScheduler =()=>{
        setLoading(true)
        axios_set_etl_refresh_status(schedulerEnabled).then(data =>{
            setLoading(false)
            if (data.error){
                console.log(data)
                return
            }
            setSuccess(true)
        })
    }

    
    useEffect(()=>{
        if (!schedulerEnabled){
          axios_get_etl_refresh_status().then(data =>{
              props.setInitalELTScheduler(true)
                if (data.error){
                    console.log(data)
                    return
                }
                setSchedulerEnabled(data.data.enabled)
            })
        }
    },[])



  return (
    <div className='p-6'>

      {success ? <SuccessAlertV2 title={"ETL scheduler updated"} /> : <></>}

      <div >
        <label htmlFor="location" className="block text-medium font-medium leading-6 text-gray-900">
         RPT Transaction ETL
        </label>
        <div className='flex w-1/3'>

        {loading ?<div className='h-6 w-6'> <LoadingSpinner /> </div> : <></>}
        
        
          <div className={`${loading ? 'opacity-0' : 'opacity-100'} flex`}> 

            <EnabledSwitch enabled={schedulerEnabled} setEnabled={setSchedulerEnabled} />


          <button
            type="button"
            className="rounded-md ml-2 bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleEmailScheduler}
          >
            Save
          </button>

          </div>
          

        </div>
      </div>

      
   
</div>
  )
}







function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function EnabledSwitch(props) {

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={props.enabled}
        onChange={props.setEnabled}
        className={classNames(
            props.enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            props.enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3 text-sm">
        {props.enabled ?
        <span className="font-medium text-gray-900">Start ETL</span>
        :
        <span className="font-medium text-gray-900">Disable ETL</span>
}
      </Switch.Label>
    </Switch.Group>
  )
}