import React, {useEffect, useState} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import GenericFooter from 'components/navigation/footer/GenericFooter'
import TabNaviation from './components/tabNaviation'
import {axios_get_po_records_power_user, axios_get_sales_records_power_user, downloadJsonContents} from './support_functions/axiosRptGetRecords'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import WarningAlert from 'components/alert/WarningAlert'
import { rptUserDispatch } from 'state/action-creator/AuthCreator'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ConvertSessionToRptUserData from './support_functions/ConvertSessionToUserData'
import PowerUserFilter from './components/powerUserFilter/powerUserFilter'
import { useSelector } from 'react-redux'

export default function RptPowerUser() {
  const dispatch = useDispatch()
  const actions = bindActionCreators({rptUserDispatch}, dispatch)
  const permissions = useSelector(state => state.rptUser.rpt_permissions)
  
  const [userPos,setUserPos] = useState([])
  const [userSds,setUserSds] = useState([])
  const [userPoFileUrl, setUserPoFileUrl] = useState("")
  const [userSdFileUrl, setUserSdFileUrl] = useState("")
  const [poDataFetchDone, setPoDataFetchDone] = useState(false)
  const [sdDataFetchDone, setSdDataFetchDone] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showNoActionRequired, setShowNoActionRequired] = useState(false)
  const [showUserPermissionError, setShowUserPermissionError] = useState(false)
  const [powerUserSelections, setPowerUserSelections] = useState()


  useEffect(()=>{
    if (sdDataFetchDone && poDataFetchDone){
      setShowLoading(false)

      if (!userPos.length && !userSds.length){
        setShowNoActionRequired(true) 
      }
    }
  },[poDataFetchDone, sdDataFetchDone])

  useEffect(()=>{
    if (powerUserSelections == null){
      return
    }
    setShowLoading(true)
    setPoDataFetchDone(false)
    setUserPos([])
    setShowNoActionRequired(false)
    axios_get_po_records_power_user(powerUserSelections).then(data =>{
      if (data.error){
        setPoDataFetchDone(true)
        console.log(data)
        return
      }

      if (data.data.jsonUrl){
        downloadJsonContents(data.data.jsonUrl).then(data =>{
          setUserPos(data)
          setPoDataFetchDone(true)
        })
      }else{
        setPoDataFetchDone(true)
      }

      if (data.data.fileUrl){
        setUserPoFileUrl(data.data.fileUrl)
      }
    })
  },[powerUserSelections])


  useEffect(()=>{
    if (powerUserSelections == null){
      return
    }
    setShowLoading(true)
    setSdDataFetchDone(false)
    setUserSds([])
    setShowNoActionRequired(false)
    axios_get_sales_records_power_user(powerUserSelections).then(data =>{
      if (data.error){
        setSdDataFetchDone(true)
        console.log(data)
        return
      }
      if (data.data.jsonUrl){
        downloadJsonContents(data.data.jsonUrl).then(data =>{
          setUserSds(data)
          setSdDataFetchDone(true)
        })
      }else{
        setSdDataFetchDone(true)
      }
      if (data.data.fileUrl){
        setUserSdFileUrl(data.data.fileUrl)
      }
    })
  },[powerUserSelections])


  useEffect(()=>{
    ConvertSessionToRptUserData().then(data =>{
        if (data === null ){
            history.push({pathname: '/', state :{permissionIssue: 'true'}});
            return null
        }
        actions.rptUserDispatch('rpt_permissions', data.rpt_permissions)

        if (data.rpt_permissions){          
          if (!data.rpt_permissions.includes("POWER")){
            setShowUserPermissionError(true)
          }
        }
    })

  },[])



  
  return (
    <div>
        <Navbar activePage={"/rpt"} />

        <div className='mt-4 ease-in-out transition-opacity'>
          <div className='p-4 w-1/2 mx-auto rounded-md'>
              <h1 className='text-4xl text-center font-semibold'>Related Party Transactions</h1>
              <br/>
              <h2 className='text-3xl text-center font-medium'>Power Users</h2>
          </div>
        </div>

        <PowerUserFilter loading={showLoading} setPowerUserSelections={setPowerUserSelections}  />

        <div className='w-[80%] mx-auto'>
          <div className={`${showNoActionRequired ? "opacity-100" : "opacity-0" } transition-opacity delay-700`}> <p className='text-2xl text-left font-semibold'>No results found</p>  </div>
        </div>
        
        {showUserPermissionError ? <div className='mx-auto w-1/2'> <WarningAlert title={"You dont have the required permissions"} /></div> : 
      

          <div className='w-[80%] mx-auto'>
            {showLoading ? <LoadingSpinner /> : <></>}
            
            {userPos.length > 0 || userSds.length > 0 || permissions.includes("ADMIN") ?
              <TabNaviation
                userPos={userPos}
                userSds={userSds}
                userPoFileUrl={userPoFileUrl}
                userSdFileUrl={userSdFileUrl}
              />
              :
              <></>
            }


                


          </div>

        }
        <GenericFooter />
    
    </div>
  )
}
