import React, {useState} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'

import { LockClosedIcon } from '@heroicons/react/outline'
import CheckUserPermissions from './components/userPermissions/getUserPermissions'
import UpdateUserPermission from './components/updateUserPermissions/updateUserPermission'

export default function RPT_UserV4() {

    const [userProfile, setUserProfile] = useState({})
    const [userGid, setUserGid] = useState("")
    const [stage, setStage] = useState(0)

    return(
        <div>
            <Navbar activePage="/admin" />
            <div className="flex mt-6">
                <div className="m-auto w-1/3">
                    <div className="p-8 shadow-lg rounded-xl  text-center bg-white">
                    <LockClosedIcon className='h-16 m-auto bg-purple-300 rounded-full p-2' />
                    <h1 className="text-3xl font-bold text-gray-800 mt-4 mb-6">RPT User Management</h1>

                        {stage === 0 ? <CheckUserPermissions  setUserGid={setUserGid} setStage={setStage} setUserProfile={setUserProfile}/> : <></>}
                        {stage === 1 ? <UpdateUserPermission userGid={userGid} userProfile={userProfile} setUserGid={setUserGid} setStage={setStage} stage={stage}/> : <></>}
             
                  </div>
                </div>
            </div>
        </div>

    )
}