import React from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import {Link} from 'react-router-dom'

import userIcon from 'pages/admin/assets/user.png'
import RPTuserIcon from 'pages/admin/assets/RPT_user.png'

//import metricsPicture from 'pages/admin/assets/metricsPicture.jpg'
//https://www.pexels.com/photo/airport-bank-board-business-534216/
//Free to use license 
export default function Admin() {
    return (
	<div>
		<Navbar activePage="/admin" />
			<div className="flex w-screen h-full p-10 justify-center">

				<div className="">

					<div className="flex flex-col bg-gray-200 rounded-lg p-4 m-2 ">
						<img src={userIcon} className="h-40 w-40 m-auto rounded-lg" alt='User Icon' loading='lazy'></img>
						<div className="flex flex-col items-start mt-4">
							<h4 className="text-2xl font-semibold">User Management</h4>
							<p className="text-lg">Create user and/or update permissions.</p>

							<Link to ='/users' className='m-auto'>
								<button className="p-2 leading-none rounded font-medium mt-3 bg-gray-400 text-lg h-12">Take me there</button>
							</Link>
						</div>
					</div>

				</div>

				<div className="">

					<div className="flex flex-col bg-gray-200 rounded-lg p-4 m-2 ">
						<img src={RPTuserIcon} className="h-40 w-40 m-auto rounded-lg" alt='User Icon' loading='lazy'></img>
						<div className="flex flex-col items-start mt-4">
							<h4 className="text-2xl font-semibold">RPT User Management</h4>
							<p className="text-lg">Create user and/or update permissions.</p>

							<Link to ='/rpt_users' className='m-auto'>
								<button className="p-2 leading-none rounded font-medium mt-3 bg-gray-400 text-lg h-12">Take me there</button>
							</Link>
						</div>
					</div>

				</div>

			</div>
	</div>

    )
}
