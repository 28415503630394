import React, {useEffect, useState} from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import { useSelector } from 'react-redux'
import ConvertSessionToRptUserData from './support_functions/ConvertSessionToUserData'
import GenericFooter from 'components/navigation/footer/GenericFooter'
import TabNaviation from './components/tabNaviation'
import {axios_get_po_records, axios_get_sales_records, downloadJsonContents} from './support_functions/axiosRptClassification'
import LoadingSpinner from 'components/loading/LoadingSpinner'

export default function RptClassification() {
  const user = useSelector(state => state.user)
  const [userPos,setUserPos] = useState([])
  const [userSds,setUserSds] = useState([])
  const [userPoFileUrl, setUserPoFileUrl] = useState("")
  const [userSdFileUrl, setUserSdFileUrl] = useState("")
  const [poDataFetchDone, setPoDataFetchDone] = useState(false)
  const [sdDataFetchDone, setSdDataFetchDone] = useState(false)
  const [showLoading, setShowLoading] = useState(true)
  const [showNoActionRequired, setShowNoActionRequired] = useState(false)


  useEffect(()=>{
    if (sdDataFetchDone && poDataFetchDone){
      setShowLoading(false)

      if (!userPos.length && !userSds.length){
        setShowNoActionRequired(true) 
      }
    }
  },[poDataFetchDone, sdDataFetchDone])




  useEffect(()=>{
    axios_get_po_records(user.user.gid).then(data =>{
      if (data.error){
        console.log(data)
        return
      }

      if (data.data.jsonUrl){
        downloadJsonContents(data.data.jsonUrl).then(data =>{
          setUserPos(data)
          setPoDataFetchDone(true)
        })
      }else{
        setPoDataFetchDone(true)
      }

      if (data.data.fileUrl){
        setUserPoFileUrl(data.data.fileUrl)
      }


      
    })
  },[])


  useEffect(()=>{
    axios_get_sales_records(user.user.gid).then(data =>{
      if (data.error){
        console.log(data)
        return
      }

      if (data.data.jsonUrl){
        downloadJsonContents(data.data.jsonUrl).then(data =>{
          setUserSds(data)
          setSdDataFetchDone(true)
        })
      }else{
        setSdDataFetchDone(true)
      }

      if (data.data.fileUrl){
        setUserSdFileUrl(data.data.fileUrl)
      }

      
    })
  },[])


  useEffect(()=>{
    ConvertSessionToRptUserData()
  },[])

  
  return (
    <div>
        <Navbar activePage={"/rpt"} />

        <div className='mt-4'>
          <div className='flex justify-center p-4 w-1/2 mx-auto rounded-md'>
              <h1 className='text-4xl text-center font-semibold'>Classify Related Party Transaction</h1>
          </div>
        </div>
        
        <div className='w-[80%] mx-auto'>
          {showLoading ? <LoadingSpinner /> : <></>}
          
          {userPos.length > 0 || userSds.length > 0 ?
            <TabNaviation
              userPos={userPos}
              userSds={userSds}
              userPoFileUrl={userPoFileUrl}
              userSdFileUrl={userSdFileUrl}
            />
            :
            <></>
          }


              <div className={`${showNoActionRequired ? "opacity-100" : "opacity-0" } transition-opacity delay-700`}> <p className='text-2xl text-left font-semibold'>You are good to go!</p>  </div>


        </div>


        <GenericFooter />
    
    </div>
  )
}
