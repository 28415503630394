

export const userReducer = (state = {user: null, permissions: []}, action) =>{
    switch (action.type){
        case 'user':
            return { ...state, user: action.data};
        case 'permissions':
            return { ...state, permissions: action.data};
        default:
            return state;
    }

}



export const rptUserReducer = (state = {rpt_permissions: []}, action) =>{
    switch (action.type){
        case 'rpt_permissions':
            return { ...state, rpt_permissions: action.data};
        default:
            return state;
    }

}

