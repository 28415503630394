import {combineReducers} from 'redux';
import {userReducer} from './AuthReducer';
import { rptUserReducer } from './AuthReducer';

const reducers = combineReducers({
    user : userReducer,
    rptUser : rptUserReducer
})

export default reducers;
