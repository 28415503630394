
import { configureStore } from "@reduxjs/toolkit";
import reducers from "./reducers/Index";
import thunk from 'redux-thunk'

export const store = configureStore ({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), thunk],
}
)

