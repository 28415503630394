
import { SSO_URL } from 'globals/variables/axiosVariables'

// send user to myID URL
export const RedirectToMyID =(pathName) => {
    if (pathName){
        const sso_URL = SSO_URL + "&state=" + pathName
        return window.location.assign(sso_URL)
    }
    return window.location.assign(SSO_URL)
}
