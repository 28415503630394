import React, { Suspense, useState, useEffect } from 'react'
import Navbar from 'components/navigation/navbar/Navbar'
import GenericFooter from 'components/navigation/footer/GenericFooter';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { Axios_gmm_verificaiton, axios_gmm_verification_poll } from 'pages/apDashboard/pages/gmmVerification/support_functions/axiosRequest'
import WarningAlert from 'components/alert/WarningAlert';
import InputField from './components/inputField';
const SuccessAlert = React.lazy(() => import('components/alert/SuccessAlert'));


export default function GmmVerification() {
    const[ fileUrl, setFileUrl] = useState()
    const[ processUUID, setProcessUUID] = useState()
    
    const [fetchingData, setFetchingData] = useState(false)
    const [errorGettingData, setErrorGettingData] = useState(false)
    const [successMesssage, setSuccessMesssage] = useState(false)
    const [searchInput, setSearchInput] = useState([])

    

    const startPolling = (uuid, pollCounter)=>{
        if (pollCounter === 24){
            setErrorGettingData(true)
            setFetchingData(false)
            clearTimeout(startPolling)
            return 
        }
        axios_gmm_verification_poll(uuid).then(data =>{
                if (data.error){
                    setErrorGettingData(true)
                    setFetchingData(false)
                    return
                }
                if (!data.data.processing){
                    setFileUrl(data.data.fileUrl)
                    clearTimeout(startPolling)
                    return
                }
                setTimeout(()=>startPolling(uuid,pollCounter+1), 5000);
            })
    }



    const handleSubmit = () => {
        setSuccessMesssage(false)
        let searchlist = searchInput.split("\n")
        let cleanedSet = new Set()
        for (let i = 0; i < searchlist.length; i++) {

            if (searchlist[i] === "" || searchlist[i] === '#NA') {
                continue
            }
            cleanedSet.add(searchlist[i])
        }

        setFetchingData(true)
        setErrorGettingData(false)
        setSuccessMesssage(false)

        Axios_gmm_verificaiton(Array.from(cleanedSet)).then(data => {
            if (data.error){
                setErrorGettingData(true)
                setFetchingData(false)
                return
            }
            setProcessUUID(data.data.uuid)
            return
        })
    }


    useEffect(()=>{
        if(processUUID){
            startPolling(processUUID, 0)
        }
    },[processUUID])



    useEffect(()=>{
        if(fileUrl){
            setSuccessMesssage(true)
            setFetchingData(false)
            window.open(fileUrl, "_blank")
        }
    },[fileUrl])



    return (
        <div>
            <Suspense fallback={<LoadingSpinner />}>
                <div>
                    <Navbar activePage="/ap_dashboard" />
                </div>


                <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md mt-16">
                        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">GMM Report</h1>
                        <h1 className="mt-1 text-center text-xl font-semibold text-gray-900">Insert rows to run checks</h1>
                    </div>

                    {successMesssage ? <div className="w-1/4 m-auto"><SuccessAlert title={'Export complete'} /></div> : <></>}
                    {errorGettingData ? <div className="w-1/4 m-auto"><WarningAlert title={'There was an error'} subtitle={'Contact Bernd or Kevin'} /></div> : <></>}




                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

                        <div className="flex justify-center mt-4 mb-6">
                            <InputField setSearchInput={setSearchInput} />
                        </div>

                        <div className="mb-12 flex justify-center m-auto">
                            {fetchingData ?
                                <button
                                    type="button"
                                    className="px-4 py-2 border cursor-progress border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-200"
                                    disabled
                                >
                                    Please Wait
                                </button>

                                :
                                <button
                                    type="button"
                                    className="px-4 py-2 border cursor-pointer border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={handleSubmit}
                                >
                                    Search
                                </button>

                            }
                        </div>
                    </div>

                </div>

                <div>
                    {fetchingData ?
                        <LoadingSpinner />

                        :
                        <></>
                    }
                </div>



            </Suspense>

            <div>
                <GenericFooter />
            </div>
        </div>
    )
}