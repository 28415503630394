import React from 'react'
import { AreSlections, PeriodSelections, EspritAccounts } from './defaultDropDowns'

export default function UserSelectionsV2(props) {


  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div>
        <div className="flex mt-5 justify-center">        
            <div className="mx-auto">
            <p className='bg-white text-gray-700 text-lg font-medium text-center'>Select ARE: </p>
                    <select
                        id="fy"
                        name="fy"
                        className={`text-lg mt-1 block border-2 text-gray-700 font-medium text-center w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                        defaultValue='Select ARE'
                        ref={props.selectedAreRef}
                    >
                        <option className="text-lg" disabled>Select ARE</option>
                        {AreSlections.map(i =>{
                        return <option key={i} className="text-lg">{i}</option>
                        })}
                    </select>
                </div>
                <div className="mx-auto">
                <p className='bg-white text-gray-700 text-lg font-medium text-center'>Select Esprit Account: </p>
                    <select
                        id="fy"
                        name="fy"
                        className={`text-lg mt-1 block border-2 text-gray-700 font-medium text-center w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                        defaultValue='Select Account'
                        ref={props.espritAccountRef}
                    >
                        <option className="text-lg" disabled>Select Account</option>
                        {EspritAccounts.map(i =>{
                        return <option key={i} className="text-lg">{i}</option>
                        })}
                    </select>
                </div>
        </div>

        <div className="flex mt-5 justify-center">                    
            <div className={`bg-white text-gray-700 text-lg font-medium text-center`}> 
            <p className='bg-white text-gray-700 text-lg font-medium text-center'>From: </p>
                    <div className="flex justify-left">
                            <div className="bg-white sm:rounded-lg sm:px-10">
                                <div className="">

                                    <select
                                        id="fy"
                                        name="fy"
                                        className={`border-2 text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                        defaultValue='Gjahr'
                                        ref={props.fromYearRef}
                                    >
                                        <option className="text-lg" disabled>Gjahr</option>
                                        {props.yearSelections.map(i =>{
                                        return <option key={i} className="text-lg">{i}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        


                        <div className="flex justify-left">
                            <div className="bg-white sm:rounded-lg sm:px-10">
                            <div>

                                <select
                                    id="fp"
                                    name="fp"
                                    className="text-lg mt-1 block border-2 w-full pl-3 pr-10 py-2  border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue='Monat'
                                    ref={props.fromMonthRef}
                                >
                                    <option className="text-lg" disabled>Monat</option>
                                    {PeriodSelections.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                            </div>
                        </div>
            </div>
    

            <div className={`bg-white text-gray-700 text-lg font-medium text-center`}> 
            <p className='bg-white text-gray-700 text-lg font-medium text-center'>To: </p>
                    <div className="flex justify-left">
                            <div className="bg-white  sm:rounded-lg sm:px-10">
                                <div className="">

                                    <select
                                        id="fy"
                                        name="fy"
                                        className={`border-2 text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md`}
                                        defaultValue='Gjahr'
                                        ref={props.toYearRef}
                                    >
                                        <option className="text-lg" disabled>Gjahr</option>
                                        {props.yearSelections.map(i =>{
                                        return <option key={i} className="text-lg">{i}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                        


                        <div className="flex justify-left">
                            <div className="bg-white sm:rounded-lg sm:px-10">
                            <div className="">

                                <select
                                    id="fp"
                                    name="fp"
                                    className=" border-2 text-lg mt-1 block w-full pl-3 pr-10 py-2 border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue='Monat'
                                    ref={props.toMonthRef}
                                >
                                    <option className="text-lg" disabled>Monat</option>
                                    {PeriodSelections.map(i =>{
                                    return <option key={i} className="text-lg">{i}</option>
                                    })}
                                </select>
                            </div>
                            </div>
                        </div>
                </div>
    
        </div>

        <div className="flex mt-5 w-full justify-center">                    
        <legend className="sr-only">View Entire Balance</legend>
            
            <div className="space-y-1">
            <p className='bg-white text-gray-700 text-lg font-medium text-center'>View Entire Balance: </p>
                <div className="relative flex justify-center">
                    <div className="flex items-center">
                        <input
                        ref={props.entireBalanceRef}
                        defaultValue={false}
                        type="checkbox"
                        className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                    </div>
                </div>
            </div>
    
        </div>

    
    </div>


    </div>
  )
}
