
import axios from 'axios'
import { sapDashboardAPIBaseuri } from 'globals/variables/axiosVariables';

export const axios_post_sales_document_power_user = async  (data ) =>{

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'rpt_classification/poweruser/post_sales_document/',
            headers: { 'content-type': 'application/json' },
            data: data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return { error : false}
        }else{
            return { error : true}
        }

    }catch(e){
        return { error : true}
      
    }
}

export const axios_post_purchase_order_power_user = async  (data ) =>{

    try{
        const response = await axios({
            method: 'POST',
            url: sapDashboardAPIBaseuri + 'rpt_classification/poweruser/post_purchase_order/',
            headers: { 'content-type': 'application/json' },
            data: data,
            withCredentials: true,
        });
        
        if (response.status === 200){
            return {error : false}
        }else{
            return { error : true}
        }

    }catch(e){
        return { error : true}
      
    }
}