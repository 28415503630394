import React, {useState, useEffect} from "react";
import SuccessAlert from "components/alert/SuccessAlert";
import WarningAlert from "components/alert/WarningAlert";
import { axiosUpdateRPTUserPermissions } from "pages/userV4_RPT/support_functions/axiosUpdateUserPermissions";
import { axiosGetAllRPTPermissions } from "pages/userV4_RPT/support_functions/axiosGetPermissions";

export default function UpdateUserPermission(props){
    const[permissions, setPermissions] = useState([])
    const[userPermissions, setUserPermissions] = useState([])
    const [showSuccessMessage, setShowSuccessMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)
    const [showStartover, setShowStartover] = useState(false)

    const [showErrorMessage, setShowErrorMessage] = useState("")
    const [showError, setShowError] = useState(false)

    const filterOnChange = (e) =>{
        let checkbox_value = e.target.value
        if (userPermissions.includes(checkbox_value)){
            let tem_array = []
            tem_array = userPermissions.filter(i => {
                return i !== checkbox_value
            })
            setUserPermissions(tem_array)
        }else{
            setUserPermissions([...userPermissions,checkbox_value])
        }
    }

    const startOver = () =>{
        props.setUserGid("")
        props.setStage(0)
    }

    const backButtonClick = ()=>{
        props.setStage(props.stage -1 )
    }

    const updatePermissionsButton = ()=>{

        axiosUpdateRPTUserPermissions(props.userGid, userPermissions).then(data =>{
            if (data.error){
                setShowError(true)
                setShowErrorMessage("there was an error")
                return
            }

            setShowStartover(true)
            setShowSuccess(true)
            setShowSuccessMessage("successfully created")
        })

    }


    useEffect(()=>{
        axiosGetAllRPTPermissions().then(data=>{
             if (data.error){
                console.log("cant read permissions")
                 return
             }
             setPermissions(data.data.data)
         })
     },[props.userGid])

     
     useEffect(()=>{
        setUserPermissions(props.userProfile)
    },[props.userGid])

    return(
        <div className="h-1/2 overflow-contain">
           {showSuccess ? <SuccessAlert title={showSuccessMessage} /> : <></>}
           {showError ? <WarningAlert title={showErrorMessage} /> : <></>}
            <div >
                <table className="w-full max-h-2/3">
                    <thead className="bg-gray-50 sticky top-0 w-[30rem] block mx-auto">
                        <tr className="w-[30rem] justify-center">
                        <th
                            scope="col"
                            className="px-6 py-3 text-center font-semibold text-gray-500 w-[15rem]"
                        >
                            Permission
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 font-semibold text-gray-500 w-[15rem] text-right mr-6"
                        >
                            Granted
                        </th>

                        </tr>
                    </thead>
                    <tbody className="h-96 overflow-y-scroll overflow-x-hidden grid w-[30rem] mx-auto">
                        {
                            permissions.map((i) => {
                                let checked = userPermissions.includes(i)
                                return(
                            <tr key={i} className={'border-b transition duration-100 ease-in-out hover:bg-gray-100  w-[30rem]'}>
                                <td className="px-6 py-4 font-medium text-gray-900 text-center w-[15rem]">{i}</td>

                                <td className="px-6 py-4 text-right w-[15rem]">
                                    <input className="text-purple-500 w-4 h-4 mr-6 focus:ring-purple-400 focus:ring-opacity-25 border border-gray-200 rounded text-left transition duration-500" 
                                    checked={checked} onChange={filterOnChange} type="checkbox" value={ i } />
                                </td>
                            </tr>
                                )
                            }
                            )
                        }
                    </tbody>
                </table>
            </div>


            <div className=" mt-4 px-4 py-3 text-center sm:px-6 flex justify-center gap-12">
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-pink-800 opacity-60 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={backButtonClick}
                  >
                    Go Back
                  </button>

                  {showStartover ?
                  <button
                  type="submit"
                  className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  onClick={startOver}
                >
                  Create another user
                </button>
                  
                  : 
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                    onClick={updatePermissionsButton}
                  >
                    Update permissions
                  </button>
                }
                </div>
        </div>
    )
}