
import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import ModalsSwitchFirstQuestion from './modalsSwitchFirstQuestion'
import ModalSwitchSecondQuestion from './modalSwitchSecondQuestion'
import { axios_post_purchase_order } from '../../support_functions/axiosRegisterUserClassifications'
import WarningAlert from 'components/alert/WarningAlert'

export default function ConfirmationModals(props) {
    const cancelButtonRef = useRef(null)
    const [question1, setquestion1] = useState(false)
    const [question2, setquestion2] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleCancel = ()=>{
      props.setShowMoldar(false)      
    }

    
    const handleSave = ()=>{
      if (showSuccess){
        return
      }
      setLoading(true)

      let data = {market_conditions: question2, ordinary_course:question1, mandt : props.entryPrimayKey.mandt
        , system : props.entryPrimayKey.system, user : props.entryPrimayKey.user,  ebeln : props.entryPrimayKey.ebeln 
      }

      axios_post_purchase_order(data).then(data =>{
        setLoading(false)

        if (data.error){
          setShowError(true)
          return
        }

        setShowSuccess(true)
        setTimeout(handleCancel, 2000);
        props.setSubmittedEbeln([...props.submittedEbeln,props.entryPrimayKey.ebeln])

      })

    }

  return (
    <Transition.Root show={props.showMoldar} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>


                {showSuccess ? 
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  : 
                  <></>
                }

                {showError ? 
                
                <div className="mx-auto flex items-center">
                  <WarningAlert title={"Error registering response, contact RPT Team"} />
                </div>
                :
                <></>              
              }

                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Related Party Transaction Classification
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        <ModalsSwitchFirstQuestion ebeln={props.entryPrimayKey.ebeln} setResponse={setquestion1} />
                      </p>

                      <p className="text-sm text-gray-500 mt-4">
                        <ModalSwitchSecondQuestion ebeln={props.entryPrimayKey.ebeln}  setResponse={setquestion2} />
                      </p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <p className='text-sm font-semibold'>For further details please contact the SE RPT team: 
                      <a href= {`mailto:postformation_siemens.energy@siemens-energy.com?subject=Question about PO ${props.entryPrimayKey.ebeln}`} className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"> Email RPT Team </a>
                    </p>
                  
                  
                  <p className='text-sm font-semibold'>Can you also reference SE Circular 1_C_54</p>
                  
                  </div>
                </div>

              {loading 
              ?

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-200 px-3 py-2 text-sm font-semibold cursor-progress text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    
                  >
                    Please wait
                  </button>

                </div>

                :

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => handleSave()}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => handleCancel()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                </div>
                }

                
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
