import React, {useState, useEffect} from 'react'
import PurchaseOrderView from './purchaseOrders/purchaseOrderView'
import SalesDocumentView from './salesOrders/salesDocumentView'
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function TabNaviation(props) {
    const [tabs, setTabs] = useState([
        { name: 'Sales Orders',  current: true, hidden: false },
        { name: 'Purchase Orders',  current: false, hidden: false },
      ])




    const handleOpenTab = (tabName)=>{
        let temTabs = []
        tabs.forEach(data=>{
            if (data.name == tabName){
                data.current = true
            }else{
                data.current = false
            }
            temTabs.push(data)
        })

        setTabs([...temTabs])
    }

    useEffect(()=>{
      var defaultTabs = []
      var currentTab = false


      if (props.userPos && props.userPos.length > 0){
        currentTab = true
        defaultTabs.push({ name: 'Purchase Orders', current: currentTab, hidden: !currentTab })
      }else{
        defaultTabs.push({ name: 'Purchase Orders', current: false, hidden: true })
      }

      if (props.userSds && props.userSds.length > 0){
        if (!currentTab){
          defaultTabs.push({ name: 'Sales Orders', current: true, hidden: false })
        }else{
          defaultTabs.push({ name: 'Sales Orders', current: false, hidden: false })
        }
      }else{
        defaultTabs.push({ name: 'Sales Orders', current: false, hidden: true })
      }

      setTabs(defaultTabs)

    },[props])

  return (
    <div>

      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
            
              <a
                key={tab.name}
                onClick={()=>handleOpenTab(tab.name)}
                hidden={tab.hidden}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-semibold'
                   
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>

      
      {tabs.map((tab)=>(
        tab.name === 'Purchase Orders' && tab.current 
        ? <PurchaseOrderView key={1} poRows={props.userPos} poUrl={props.userPoFileUrl} />: <></>
      ))}

      {tabs.map((tab)=>(
        tab.name === 'Sales Orders' && tab.current 
        ? <SalesDocumentView key={1} sdRows={props.userSds} sdUrl={props.userSdFileUrl} />: <></>
      ))}
   
    </div>
  )
}

