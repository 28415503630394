import React, {useState, useEffect} from 'react'
import FiscalYearSetting from './components/fiscalYearSetting'
import EmailScheduler from './components/emailScheduler'
import LoadingSpinner from 'components/loading/LoadingSpinner'
import ReminderEmail from './components/reminderEmail'
import EtlDailyRefreshScheduler from './components/etlDailyRefreshScheduler'
export default function RptApplicationSettings(props) {

  const [initalFiscalYear, setInitalFiscalYear] = useState(false)
  const [initalEmailScheduler, setInitalEmailScheduler] = useState(false)
  const [initalELTScheduler, setInitalELTScheduler] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    if (initalEmailScheduler && initalFiscalYear &&initalELTScheduler){
      setLoading(false)
    }

  },[initalFiscalYear, initalEmailScheduler,initalELTScheduler])

  return (
    <div className={`${props.show ? 'opacity-100' : 'opacity-0'} duration-75 transition-opacity ease-in-out px-4 sm:px-6 lg:px-8 mt-2`}>
      {loading ?<LoadingSpinner /> : <></>}
        <div className={`${loading ? `opacity-0` : `opacity-100`} duration-75 transition-opacity ease-in-out`}>
          <FiscalYearSetting setInitalFiscalYear={setInitalFiscalYear} />
          <EmailScheduler setInitalEmailScheduler={setInitalEmailScheduler} />
          <ReminderEmail />
          <EtlDailyRefreshScheduler setInitalELTScheduler={setInitalELTScheduler}/>
        </div>
      
    </div>
  )
}
