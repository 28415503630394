import React, { useRef } from "react"


export default function InputField(props) {

    const inputFieldRef = useRef()
    let defaultText = ''

    const updateInputField = () =>{
        props.setSearchInput(inputFieldRef.current.value)
    }

    switch (props.selectedReportName){
      case 'change_request':
        defaultText = "Insert CR Number"
        break;
      case 'bp_id':
        defaultText = "Insert BP Number"
        break;
      default:
        defaultText = "Select search type"
    }






    return (
      <div className='w-2/3 h-full rounded-md p-1 border border-gray-300 shadow-sm'>
        <input
          className="w-full h-full px-4 py-3"
          placeholder={defaultText}
          ref={inputFieldRef}
          onChange={updateInputField}
        />
      </div>
    )
  }

  